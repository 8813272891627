import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import jQuery from 'jquery'
// import {VueReCaptcha} from 'vue-recaptcha-v3'
//
// Vue.use(VueReCaptcha, {siteKey: '6LeZJaQZAAAAAAFkERcmCKWJ7oZZZ_gE1uG5OsBf'})

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/37994.js'
import '@/assets/83e97.js'
// import '@/assets/Fix1.css'
// import '@/assets/Global2.css'
import '@/assets/Webshop-ResengoCloud.css'
import '@/assets/762fd.css'
import '@/assets/style.css'
import vuetify from './plugins/vuetify';
/* Use versioning */
const pck              = require('../package.json')
const version          = pck.version
const name             = pck.name
Vue.prototype.$version = version
Vue.prototype.$appname = name
/* Configure axios base url */
var envURL
if (process.env.NODE_ENV === 'development') {
    envURL = pck.developmentUrl
} else {
    envURL = pck.productionUrl
}
/* axios */
const axiosConfig    = {
    baseURL: envURL,
}
Vue.prototype.$axios = axios.create(axiosConfig)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: function (h) { return h(App) }
}).$mount('#app')
