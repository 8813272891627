import Vue       from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: {name: 'home'}
    },
    {
        path: '/home',
        name: 'home',
        component: function () {
            return import(/* webpackChunkName: "home" */ '../views/HomeNew.vue')
        }
    },
    {
        path: '/reserveren',
        name: 'reserveren',
        component: function () {
            return import(/* webpackChunkName: "reserveren" */ '../views/ReserverenNew.vue')
        }
    },
    {
        path: '/cadeaubon',
        name: 'cadeaubon',
        component: function () {
            return import(/* webpackChunkName: "cadeaubon" */ '../views/CadeaubonNew.vue')
        }
    },
    {
        path: '/openingsuren',
        name: 'openingsuren',
        component: function () {
            return import(/* webpackChunkName: "openingsuren" */ '../views/Openingsuren.vue')
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: function () {
            return import(/* webpackChunkName: "contact" */ '../views/ContactNew.vue')
        }
    },
    {
        path: '/menus',
        name: 'menu',
        component: function () {
            return import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
        }
    },
    {
        path: '/afhaal',
        name: 'afhaal',
        component: function () {
            return import(/* webpackChunkName: "afhaal" */ '../views/TakeAway.vue')
        }
    },
    {
        path: '/privacy-statement',
        name: 'privacy',
        component: function () {
            return import(/* webpackChunkName: "privacy" */ '../views/PrivacyStatement.vue')
        }
    },
    {
        path: '/payment/:id',
        name: 'payment',
        component: function () {
            return import(/* webpackChunkName: "payment" */ '../views/Payment.vue')
        }
    }
]
const router = new VueRouter({
    // mode: 'history',
    routes
})
export default router
